import React, { useState } from 'react'
import { useQuery } from "@apollo/client"
import { GET_ALL_CITIES } from "../../components/graphql"
import Select from 'react-select'

export default function School() {
  const maximumLimit = 30000
  const [cityName, setCityName] = useState('')
  const [schoolName, setSchoolName] = useState('')
  const [tuitionFee, setTuitionFee] = useState('0.0')
  const [extraAmountList, setExtraAmountList] = useState([{ expenseName: "", expenseAmount: "" }]);
  const [percentList, setPercentList] = useState([]);

  React.useEffect(() => {
    console.log("schools reloadedddddd2222222222222",sessionStorage.getItem('schoolName'),"-----",sessionStorage.getItem('tuitionFee'))
    if(!sessionStorage.getItem('userRedirectFlag')){
      sessionStorage.setItem('activeStep',0)
    }
    setSchoolName(sessionStorage.getItem('schoolName') ? sessionStorage.getItem('schoolName') : '')
    setTuitionFee(sessionStorage.getItem('tuitionFee') ? sessionStorage.getItem('tuitionFee') : '0.0')
    setExtraAmountList(JSON.parse(sessionStorage.getItem('extraAmountList')) ? JSON.parse(sessionStorage.getItem('extraAmountList')) : [{ expenseName: "", expenseAmount: "" }])
    setCityName(JSON.parse(sessionStorage.getItem('cityName'))? JSON.parse(sessionStorage.getItem('cityName')) :null)
    setPercentList(JSON.parse(sessionStorage.getItem('step1Percent')) ? JSON.parse(sessionStorage.getItem('step1Percent')) : [])
  }, []);

  React.useEffect(() => {
    console.log("listtttttttt", percentList);
    callPercentFill();
    return () => {
      console.log("unmount schools",schoolName,tuitionFee,JSON.stringify(cityName),"====")
      sessionStorage.setItem('schoolName', schoolName)
      sessionStorage.setItem('tuitionFee', tuitionFee)
      sessionStorage.setItem('cityName', JSON.stringify(cityName))
      sessionStorage.setItem('extraAmountList', JSON.stringify(extraAmountList))
    }
  }, [cityName, schoolName, tuitionFee, extraAmountList]);

  React.useEffect(() => {
    return () => {
      sessionStorage.setItem('step1Percent', JSON.stringify(percentList))
    }
  }, [percentList]);


  const callPercentFill = () => {
    var listValue = [];
    var maximumLimitTemp = maximumLimit;
    var valueTemp = 0.0;
    if(parseFloat(tuitionFee) >0){
      listValue.push({
          'label':"Tuition",
          'value':parseFloat(tuitionFee ? (tuitionFee / maximumLimit) * 100 >100 ? 100 :(tuitionFee / maximumLimit) * 100 : 0).toFixed(2),
          'amount':tuitionFee ?(parseFloat(tuitionFee)>parseFloat(maximumLimit) ? maximumLimit : tuitionFee):0,
          'color':'#FFD859'
      });
      valueTemp = parseFloat(valueTemp) + parseFloat(listValue[0].value)
      maximumLimitTemp = parseFloat(maximumLimitTemp) -parseFloat( listValue[0].amount)
    }
    if (extraAmountList.length > 0) {
      extraAmountList.map((x, i) => {
        if(parseFloat(x.expenseAmount)>0){
          let tempValue = 0;
          tempValue = ( (parseFloat(x.expenseAmount)>0?parseFloat(x.expenseAmount):0) / maximumLimit) * 100;
          if(parseFloat(tempValue)<=100 && parseFloat(maximumLimitTemp) >0){
            if((parseFloat(valueTemp)+parseFloat(tempValue) > parseFloat(100) ?100-parseFloat(valueTemp):parseFloat(tempValue).toFixed(2))>0){
              listValue.push({
                'label':x.expenseName,
                'value': (parseFloat(valueTemp)+parseFloat(tempValue) > parseFloat(100) ?100-parseFloat(valueTemp):parseFloat(tempValue).toFixed(2)),
                'amount': x.expenseAmount? (parseFloat(maximumLimitTemp) -parseFloat(x.expenseAmount) <=0?parseFloat(maximumLimitTemp):x.expenseAmount):0,
                'color':""
              })
              valueTemp = parseFloat(valueTemp) +  parseFloat(tempValue);
              maximumLimitTemp = parseFloat(maximumLimitTemp) - parseFloat(x.expenseAmount? (parseFloat(maximumLimitTemp) -parseFloat(x.expenseAmount) <=0?parseFloat(maximumLimitTemp):x.expenseAmount):0);
            }
          } 
        }
      })
    }
    var schoolColorList =['#FFD859','#F47961','#35DF90','#0055A4',
          '#FFD859','#F47961','#35DF90','#0055A4',
          '#FFD859','#F47961']
    var schoolColorListInit = ['#FFD859','#F47961','#35DF90','#0055A4']
    listValue.map((x,i)=>{
      if(i<10){
        x.color = schoolColorList[i]
      }
      else{
        x.color = schoolColorListInit[Math.floor(Math.random() * schoolColorListInit.length)]
      }
    })
    listValue.push({
        'label':"",
        'value': parseFloat((100 - parseFloat(valueTemp)) > 0 ? 100 - parseFloat(valueTemp) : 0).toFixed(2),
        'amount':0,
        'color':'#FFFFFF'
    })
    setPercentList(listValue.reverse());
  }

  const getAllCitiesValues = useQuery(GET_ALL_CITIES)

  const cityOptions = getAllCitiesValues.data ? getAllCitiesValues.data.cities : []
  
  const handleExtraAmountChange = (e, index) => {

    const { name, value } = e.target;
    const list = [...extraAmountList];
    list[index][name] = value;
    setExtraAmountList(list);
  };

  const handleRemoveClick = index => {
    const list = [...extraAmountList];
    list.splice(index, 1);
    setExtraAmountList(list);
  };

  const handleAddClick = () => {
    setExtraAmountList([...extraAmountList, { expenseName: "", expenseAmount: "" }]);
  };
  
  const handleChange = e =>{
    setCityName(e);
  }
  
  const updateCityName =(e) =>{
    console.log("ee",e)
  }

  return (
    <div className="school-step-form row">
      <div className="tool-main-search-box">
        <div className="text-label">
          <span>Enter City Name</span>
        </div>
        <div className="text-input">
          <Select 
            options={cityOptions.map(city => ({ value: city.id, label: city.cityName }))}
            value={cityName}
            onChange={(e) =>{ handleChange(e)}}
            />
          <button type="submit" onClick={e => updateCityName(e)}>Search</button>
        </div>
      </div>
      <div className="mainContent column">
        <div className='row pb20'>
          <h6>School</h6>
          <div className='six columns'>
            <label>Enter your school/ college/ university name.</label><br />
            <input
              className='u-full-width'
              placeholder='School/ college/ university name'
              type='text'
              onChange={e => setSchoolName(e.target.value)}
              value={schoolName}
            />
          </div>
        </div>
        <div className='row pb20'>
          <div className='six columns equal-column'>
            <label>Enter your Tuition fees: </label>
            <div className='price-section'>
              <span className='currency-symbol'>€</span>
              <input
                className='u-full-width'
                placeholder='0.00'
                type='text'
                onChange={e => setTuitionFee(e.target.value)}
                value={ tuitionFee }
              />
            </div>
          </div>
        </div>
        <div className='row pb20 pt15'>
          <div className='six columns extra'>
            <label>Add any extra amount spent: </label>
            {extraAmountList !== null ? extraAmountList.map((x, i) => {
              return (
                <div className="box">
                  <div className="double-input">
                    <input
                      name="expenseName"
                      placeholder="Enter expense name"
                      value={x.expenseName ? x.expenseName : ""}
                      onChange={e => handleExtraAmountChange(e, i)}
                      style={{ border: 'none', borderBottom: "2px solid #808080" }}
                    />
                    <div className='price-section'>
                      <span className='currency-symbol'>€</span>
                      <input
                        className="ml10"
                        name="expenseAmount"
                        placeholder="Enter Amount"
                        value={x.expenseAmount ? x.expenseAmount : ""}
                        style={{ border: 'none', borderBottom: "2px solid #808080" }}
                        onChange={e => handleExtraAmountChange(e, i)}
                      />
                    </div>
                  </div>
                  <div className="btn-box mb-15">
                    {extraAmountList.length !== 1 && <button
                      className="mr10 remove-button-custom"
                      onClick={() => handleRemoveClick(i)}>Remove</button>}
                    {extraAmountList.length - 1 === i && <button className="add-button-custom" onClick={handleAddClick}>Add Expense <span>(click here)</span></button>}
                  </div>
                </div>
              )
            }) : null}
          </div>
        </div>
      </div>
      <div className="progressContent column" >
        <div className="progress">
          {percentList !== null ? percentList.map((x, i) => {
            return (
              i === 0 ?
                <div className={x.value === '100.00' ?
                   "progress-bar bg-white progress-bar-stripped curved":
                   `progress-bar bg-white progress-bar-stripped`} 
                   style={{ height: x.value + '%' }}>
                </div>
                :
                ( i===1?
                  <div className="progress-bar main-element"
                  style={{ height: x.value + "%" ,backgroundColor: x.color}}>
                    <span>{x.value >0 ? x.label :""}</span>
                    <div className='progress-bar content-element' style={{  height: 'calc(100%)',backgroundColor: x.color }}> 
                    </div>
                </div>
                  :
                  <div className="progress-bar main-element"
                  style={{ height: x.value + "%",backgroundColor: x.color }}>
                    <span>{x.value >0 ? x.label :""}</span>
                    <div  className="progress-bar space-element" style={{ height: '4px', backgroundColor: "#FFFFFF !important" }}>
                    </div>
                    <div className='progress-bar content-element' style={{ height: 'calc(100% - 4px)',backgroundColor: x.color }}>
                    </div>
                </div>
                )
            )
          }) : null}
        </div>
        {/* <div className="progress-content">
            <span className="content2">Estimated spends on school/year</span>
        </div> */}
        </div>
    </div>
  )
}
