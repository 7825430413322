import React, { useState } from 'react'
import Login from "../login"

export default function ExpensesNext() {
  const [open, setOpen] = useState(false)
  const [login, setLogin] = useState(false)
  const [forgotPassword, setForgotPassword] = useState(false)
  const [username, setUsername] = useState(undefined)
  const [userID, setUserID] = useState(undefined)
  const [groceryCost, setGroceryCost] = useState(0.0)
  const [transportationCost, setTransportationCost] = useState(0.0)
  const [personalCareCost, setPersonalCareCost] = useState(0.0)
  const [entertainmentCost, setEntertainmentCost] = useState(0.0)
  const [step1Percent, setStep1Percent] = useState([])
  const [percentList, setPercentList] = useState([]);
  const [otherExpense,setOtherExpense] = useState(0.0);
  const [phoneError, setphoneError] = useState('');
  const [successMessage,setSuccessMessage] = useState("");
  const [errorMessage,setErrorMessage] = useState("");

  React.useEffect(() => {
    console.log("expenses1 reloadeddddddddddddddddddddd")
    sessionStorage.setItem('activeStep',4);
    setStep1Percent(JSON.parse(sessionStorage.getItem('step1Percent')) ? JSON.parse(sessionStorage.getItem('step1Percent')) : [])
    setPercentList(JSON.parse(sessionStorage.getItem('step4Percent')) ? JSON.parse(sessionStorage.getItem('step4Percent')) :[])
    
    setGroceryCost(JSON.parse(sessionStorage.getItem('groceryCost')) ? JSON.parse(sessionStorage.getItem('groceryCost')) : 0.0)
    setTransportationCost(JSON.parse(sessionStorage.getItem('transportationCost')) ? JSON.parse(sessionStorage.getItem('transportationCost')) : 0.0)
    setPersonalCareCost(JSON.parse(sessionStorage.getItem('personalCareCost')) ? JSON.parse(sessionStorage.getItem('personalCareCost')) : 0.0)
    setEntertainmentCost(JSON.parse(sessionStorage.getItem('entertainmentCost')) ? JSON.parse(sessionStorage.getItem('entertainmentCost')) : 0.0)  
  }, []);

  React.useEffect(() => {
    var extraTempValue = 0.0;
    JSON.parse(sessionStorage.getItem('otherExpense')).map((value)=>{
      extraTempValue = parseFloat(extraTempValue) + parseFloat(value.expenseAmount?value.expenseAmount:0);
      setOtherExpense(extraTempValue)
    })
  }, [otherExpense])

  React.useEffect(() => {
    return () => {
      sessionStorage.setItem('step4Percent', JSON.stringify(percentList))
    }
  }, [percentList]);

  const loginCheck = (e) => {
    if (localStorage.getItem("userId")) {
      console.log("login")
    }
    else {
      console.log("logout")
      e.preventDefault()
      setOpen(true)
      setLogin(true)
      setSuccessMessage("")
      setErrorMessage("")
      setphoneError("")
      localStorage.setItem("redirect", true);
      sessionStorage.setItem('userRedirectFlag',true);
      localStorage.setItem('lastPage','tools')
      return false
    }
  }
  return (
    <div className="expenses-next-step-form row">
      <Login
        open={open}
        setOpen={setOpen}
        login={login}
        setLogin={setLogin}
        setUsername={setUsername}
        setUserID={setUserID}
        forgotPassword={forgotPassword}
        setForgotPassword={setForgotPassword}
        errorMessage ={errorMessage}
        setErrorMessage ={setErrorMessage}
        successMessage ={successMessage}
        setSuccessMessage ={setSuccessMessage}
        phoneError ={phoneError}
        setphoneError ={setphoneError}
      />
      <div className="mainContent column">
        <div className='row pb20'>
          <h6>Expenses</h6>
          <div className='six columns exp-next'>
            <div className="exp-next-list">
              <label>Your Grocery is expected to cost around:</label>
              <div className='price-section'>
                <span className='currency-symbol'>€</span>
                <input
                  className='u-full-width'
                  placeholder='0.0'
                  type='text'
                  onChange={e => setGroceryCost(e.target.value)}
                  value={parseFloat(groceryCost).toFixed(2)}
                  readOnly={true}
                />
              </div>
            </div>
          </div>
          <div className='six columns exp-next'>
            <div className="exp-next-list">
              <label>Transportation is expected to cost around:</label>
              <div className='price-section'>
                <span className='currency-symbol'>€</span>
                <input
                  className='u-full-width'
                  placeholder='0.0'
                  type='text'
                  onChange={e => setTransportationCost(e.target.value)}
                  value={parseFloat(transportationCost).toFixed(2)}
                  readOnly={true}
                />
              </div>
            </div>
          </div>
          <div className='six columns exp-next'>
            <div className="exp-next-list">
              <label>Personal care is expected to cost around:</label>
              <div className='price-section'>
                <span className='currency-symbol'>€</span>
                <input
                  className='u-full-width'
                  placeholder='0.0'
                  type='text'
                  onChange={e => setPersonalCareCost(e.target.value)}
                  value={parseFloat(personalCareCost).toFixed(2)}
                  readOnly={true}
                />
              </div>
            </div>
          </div>

          <div className='six columns exp-next'>
            <div className="exp-next-list">
              <label>Entertainment is expected to cost around:</label>
              <div className='price-section'>
                <span className='currency-symbol'>€</span>
                <input
                  className='u-full-width'
                  placeholder='0.0'
                  type='text'
                  onChange={e => setEntertainmentCost(e.target.value)}
                  value={parseFloat(entertainmentCost).toFixed(2)}
                  readOnly={true}
                />
              </div>
            </div>
          </div>
          <div className='six columns exp-next'>
            <div className="exp-next-list">
              <label>Other expense is expected to cost around:</label>
              <div className='price-section'>
                <span className='currency-symbol'>€</span>
                <input
                  className='u-full-width'
                  placeholder='0.0'
                  type='text'
                  value={parseFloat(otherExpense).toFixed(2)}                  
                  readOnly={true}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="progressContent column" >
        <div className="content1" style={{ width: "100%" }}>
          <div className="progress">
            {step1Percent !== null ? step1Percent.map((x, i) => {
              return (
                i == 0 ?
                  <div className={x.value === '100.00' ?
                  "progress-bar bg-white progress-bar-stripped curved":
                  "progress-bar bg-white progress-bar-stripped"}  
                  style={{ height: x.value + '%' }}>
                  </div>
                  :
                  ( i===1?
                    <div className="progress-bar main-element"
                    style={{ height: x.value + "%" ,backgroundColor: x.color}}>
                      <span>{x.value >0 ? x.label :""}</span>
                      <div className='progress-bar content-element' style={{  height: 'calc(100%)',backgroundColor: x.color }}> 
                      </div>
                  </div>
                    :
                    <div className="progress-bar main-element"
                    style={{ height: x.value + "%",backgroundColor: x.color }}>
                      <span>{x.value >0 ? x.label :""}</span>
                      <div  className="progress-bar space-element" style={{ height: '4px', backgroundColor: "#FFFFFF !important" }}>
                      </div>
                      <div className='progress-bar content-element' style={{ height: 'calc(100% - 4px)',backgroundColor: x.color }}>
                      </div>
                  </div>
                  )
              )
            }) : null}
          </div>
          {/* <div className="progress-content">
            <span className="content2">Estimated spends on school/year</span>
          </div> */}
        </div>
        <div className="content2" style={{ width: "100%" }}>
          <div className="progress">
            {percentList !== null ? percentList.map((x, i) => {
              return (
                i == 0 ?
                  <div className={x.value === '100.00' ?
                  "progress-bar bg-white progress-bar-stripped curved":
                  "progress-bar bg-white progress-bar-stripped"} 
                  style={{ height: x.value + '%' }}>
                  </div>
                  :
                  ( i===1?
                    <div className="progress-bar main-element"
                    style={{ height: x.value + "%" ,backgroundColor: x.color}}>
                      <span>{x.value >0 ? x.label :""}</span>
                      <div className='progress-bar content-element' style={{  height: 'calc(100%)',backgroundColor: x.color }}> 
                      </div>
                  </div>
                    :
                    <div className="progress-bar main-element"
                    style={{ height: x.value + "%",backgroundColor: x.color }}>
                      <span>{x.value >0 ? x.label :""}</span>
                      <div  className="progress-bar space-element" style={{ height: '4px', backgroundColor: "#FFFFFF !important" }}>
                      </div>
                      <div className='progress-bar content-element' style={{ height: 'calc(100% - 4px)',backgroundColor: x.color }}>
                      </div>
                  </div>
                  )
              )
            }) : null}
          </div>
          {/* <div className="progress-content">
            <span className="content2">Estimated spends on other expenses</span>
          </div> */}
        </div>
      </div>

      {localStorage.getItem("userId") ? null :
        <div className="custom-button submit-btn">
          <div className='submit-primary'>
            <input type="button" value="Submit" className="custom-button" onClick={(e) => { loginCheck(e) }} />
          </div>
        </div>
      }

    </div>
  )
}