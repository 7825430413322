import React, { useState,useRef } from 'react'
import { useQuery } from "@apollo/client"
import { GET_STUDENT_BUDGET_PARAMETER_VALUES, GET_ALL_CITIES } from "../../components/graphql"
import Select from 'react-select'

export default function Housing() {
  const ref = useRef(null);
  const maximumLimit = 10000
  const [housingCityName, setHousingCityName] = useState("")
  const [housingCitySelectedID, setHousingCitySelectedID] = useState("")
  // const [housingCityNameTemp, setHousingCityNameTemp] = useState("")
  const [housingExtraAmountList, setHousingExtraAmountList] = useState([{ expenseName: "", expenseAmount: "" }]);
  const [activeIndex, setActiveIndex] = useState(0)
  const [activeHousingID, setActiveHousingID] = useState()
  const [activeHousingPrice, setActiveHousingPrice] = useState(0.0)
  const [step1Percent, setStep1Percent] = useState([])
  const [percentList, setPercentList] = useState([]);
  const [limitValue,setLimitValue]=useState(0.0);

  React.useEffect(() => {
    console.log("housing reloadedddddd11111111111111111111111", housingExtraAmountList)
    sessionStorage.setItem('activeStep',1)
    setHousingCityName(JSON.parse(sessionStorage.getItem('cityName')) ? JSON.parse(sessionStorage.getItem('cityName')) : null)
    setHousingExtraAmountList(JSON.parse(sessionStorage.getItem('housingExtraAmountList')) ? JSON.parse(sessionStorage.getItem('housingExtraAmountList')) : [{ expenseName: "", expenseAmount: "" }])
    setActiveIndex(sessionStorage.getItem('activeIndex') ? sessionStorage.getItem('activeIndex') : 0)
    setActiveHousingID(sessionStorage.getItem('activeHousingID') ? sessionStorage.getItem('activeHousingID') : 0)
    setActiveHousingPrice(sessionStorage.getItem('activeHousingPrice') ? sessionStorage.getItem('activeHousingPrice') : 0.0)
    setStep1Percent(JSON.parse(sessionStorage.getItem('step1Percent')) ? JSON.parse(sessionStorage.getItem('step1Percent')) : [])
    setPercentList(JSON.parse(sessionStorage.getItem('step2Percent')) ? JSON.parse(sessionStorage.getItem('step2Percent')) : [])
    setLimitValue(sessionStorage.getItem('limitValue')?sessionStorage.getItem('limitValue'):0)
    setHousingCitySelectedID(JSON.parse(sessionStorage.getItem('cityName'))?JSON.parse(sessionStorage.getItem('cityName')).value:null)
  }, []);

  React.useEffect(() => {
    callPercentFill();
    return () => {
      console.log("unmount housing")
      sessionStorage.setItem('cityName', JSON.stringify(housingCityName));
      // sessionStorage.setItem('cityNameTemp', housingCityNameTemp);
      sessionStorage.setItem('activeHousingID', activeHousingID);
      sessionStorage.setItem('activeHousingPrice', activeHousingPrice);
      sessionStorage.setItem('activeIndex', activeIndex);
      sessionStorage.setItem('housingExtraAmountList', JSON.stringify(housingExtraAmountList)); 
      sessionStorage.setItem('housingCitySelectedID',housingCitySelectedID)
    }
  }, [housingCityName, activeHousingID,
       activeHousingPrice, activeIndex, housingExtraAmountList]);

  React.useEffect(() => {
    return () => {
      sessionStorage.setItem('step2Percent', JSON.stringify(percentList))
      sessionStorage.setItem('limitValue',limitValue)
    }
  }, [percentList,limitValue]);

  const callPercentFill = () => {
    var listValue = [];
    var maximumLimitTemp = limitValue?limitValue:maximumLimit;
    var valueTemp = 0.0;
    if(parseFloat(activeHousingPrice ? (activeHousingPrice / maximumLimit) * 100 >100 ? 100 :(activeHousingPrice / maximumLimit) * 100 : 0).toFixed(2)>0){
      listValue.push({
        'label':"Accommodation",
        'value':parseFloat(activeHousingPrice ? (activeHousingPrice / maximumLimit) * 100 >100 ? 100 :(activeHousingPrice / maximumLimit) * 100 : 0).toFixed(2),
        'amount':activeHousingPrice ?(parseFloat(activeHousingPrice)>parseFloat(maximumLimit) ? maximumLimit : activeHousingPrice):0,
        'color':'#35DF90'
      });
      valueTemp = valueTemp + parseFloat(listValue[0].value);
      maximumLimitTemp = maximumLimit - listValue[0].amount;
    }
    if (housingExtraAmountList.length > 0) {
      housingExtraAmountList.map((x, i) => {
        if(parseFloat(x.expenseAmount)>0){
          let tempValue = 0;
          tempValue = ( (parseFloat(x.expenseAmount)>0?x.expenseAmount:0) / maximumLimit) * 100;
          if(parseFloat(tempValue)<=100 && parseFloat(maximumLimitTemp) >0){ 
            if((parseFloat(valueTemp)+parseFloat(tempValue) > parseFloat(100) ?100-parseFloat(valueTemp):parseFloat(tempValue).toFixed(2))>0){
              listValue.push({
                'label':x.expenseName,
                'value': (parseFloat(valueTemp)+parseFloat(tempValue) > parseFloat(100) ?100-parseFloat(valueTemp):parseFloat(tempValue).toFixed(2)),
                'amount': x.expenseAmount? (parseFloat(maximumLimitTemp) -parseFloat(x.expenseAmount) <=0?parseFloat(maximumLimitTemp):x.expenseAmount):0,
                'color':""
              });
              valueTemp = valueTemp + parseFloat(listValue[i+1]?listValue[i+1].value:0);
              maximumLimitTemp = maximumLimitTemp - x.expenseAmount;
            }
          }
        }
      })
    }
    var housingColorList = [
    '#35DF90','#0055A4','#FFD859','#F47961',
    '#35DF90','#0055A4','#FFD859','#F47961',
    '#35DF90','#0055A4','#FFD859','#F47961',
    '#35DF90','#0055A4','#FFD859','#F47961',
    '#35DF90','#0055A4','#FFD859','#F47961']
    var housingColorListInit= ['#35DF90','#0055A4','#FFD859','#F47961']
    listValue.map((x,i)=>{
      if(i<20){
        x.color = housingColorList[i]
      }
      else{
        x.color = housingColorListInit[Math.floor(Math.random() * housingColorListInit.length)]
      }
    })
    listValue.push({
      'label': "",
      'value': parseFloat((100 - parseFloat(valueTemp)) > 0 ? 100 - parseFloat(valueTemp) : 0).toFixed(2),
      'amount': 0,
      'color':'#FFFFFF'
    })
    setPercentList(listValue.reverse());
    setLimitValue(maximumLimitTemp)
  }

  const onFocusInput =() =>{
    ref.current.focus();
  }

  const handleExtraAmountChange = (e, index) => {
    const { name, value } = e.target;
    const list = [...housingExtraAmountList];
    list[index][name] = value;
    setHousingExtraAmountList(list);
  };

  const getAllCitiesValues = useQuery(GET_ALL_CITIES)
  const cityOptions = getAllCitiesValues.data ? getAllCitiesValues.data.cities : []

  const getStudentBudgetParameter = useQuery(GET_STUDENT_BUDGET_PARAMETER_VALUES, {
    variables: { id: housingCitySelectedID },
  })

  const toggleClass = (index, id, amount) => {
    setActiveIndex(index)
    setActiveHousingID(id)
    setActiveHousingPrice(amount)
  }

  const handleRemoveClick = index => {
    const list = [...housingExtraAmountList];
    list.splice(index, 1);
    setHousingExtraAmountList(list);
  };

  const handleAddClick = () => {
    setHousingExtraAmountList([...housingExtraAmountList, { expenseName: "", expenseAmount: "" }]);
  };

  const handleChange = e =>{
    setHousingCityName(e);
    setHousingCitySelectedID(e.value);
    setActiveHousingID(0)
    setActiveIndex(0)
    setActiveHousingPrice(0)
  }

  const updateCityName =(e) =>{
    console.log("ee",e)
  }
  return (
    <div className="housing-step-form row">
      <div className="mainContent column">
        <div className='row pb20 housing'>
          <h6>Housing</h6>
          <label>Where will you be living?<span className="red-star">* </span><span>(Choose one)</span></label>
          <div className='six columns housing'>
            <div className="housing-text-input">
              <Select className="w-20"
                options={cityOptions.map(city => ({ value: city.id, label: city.cityName }))}
                value={housingCityName}
                onChange={(e) =>{ handleChange(e)}}
              />
              <button type="submit" onClick={e => updateCityName(e)}>Search</button>
            </div>
          </div>
        </div>
        {housingCityName?
          <>
            {getStudentBudgetParameter ?
              getStudentBudgetParameter.data ?
                getStudentBudgetParameter.data.city ?
                  <div className='row pb20 h-scroll'>
                    {getStudentBudgetParameter.data.city.accommodations.length>1?
                    <div className='six columns housing-options' style={{ columnCount: `${getStudentBudgetParameter.data.city.accommodations.length}` }}>
                      {getStudentBudgetParameter.data.city.accommodations.map((item, index) =>
                      (
                        <div className={activeIndex == index + 1 ? "housing-option active" : "housing-option"} onClick={() => toggleClass(index + 1, item.id, item.accommodationPrice)} >
                          <span>{item.accommodationType}</span>
                        </div>
                      ))}
                    </div>
                    : (housingCityName.value).length>1 ? <span className='no-record'>No Records found</span> :""}
                  </div>
                  : <span className='no-record'>No Records found</span>
                : <span className='no-record'>No Records found</span>
              : <span className='no-record'>No Records found</span> }
           {activeHousingID.length>1?
              <> 
                <div className='row pb20 pt15'>
                  <div className='six columns receipt'>
                    <div className="rent">
                      <label>Your monthly rent is expected to be: </label>
                      <div className='price-section'>
                        <span className='currency-symbol'>€</span>
                        <input
                          className='u-full-width'
                          placeholder='Lorem Ipsum'
                          type='text'
                          ref={ref}
                          value={activeHousingPrice?parseFloat(activeHousingPrice):0.0}
                          onChange={e => setActiveHousingPrice(e.target.value)}
                          style={{ border: 'none', borderBottom: "2px solid #808080" }}
                        />
                      </div>
                    </div>
                    <span className="blue-text link" onClick={() => onFocusInput()}>Suggest Change?</span>
                  </div>
                </div>
                <div className='row pb20'>
                  <div className='six columns other-changes'>
                    <label>Add any other Charges </label>
                    {housingExtraAmountList.map((x, i) => {
                      return (
                        <div className="box">
                          <div className="double-input">
                            <input
                              name="expenseName"
                              placeholder="Enter expense name"
                              value={x.expenseName}
                              onChange={e => handleExtraAmountChange(e, i)}
                              style={{ border: 'none', borderBottom: "2px solid #808080" }}
                            />
                            <div className='price-section'>
                              <span className='currency-symbol'>€</span>
                              <input
                                className="ml10"
                                name="expenseAmount"
                                placeholder="Enter Amount"
                                value={x.expenseAmount}
                                style={{ border: 'none', borderBottom: "2px solid #808080" }}
                                onChange={e => handleExtraAmountChange(e, i)}
                              />
                              </div>
                          </div>
                          <div className="btn-box mb-15">
                            {housingExtraAmountList.length !== 1 && <button
                              className="mr10 remove-button-custom"
                              onClick={() => handleRemoveClick(i)}>Remove</button>}
                            {housingExtraAmountList.length - 1 === i && <button className="add-button-custom" onClick={handleAddClick}>Add Expense <span>(click here)</span></button>}
                          </div>
                        </div>
                      );
                    })}

                  </div>
                </div>
              </>
            :null}
          </>
          : null}
      </div>
      <div className="progressContent column" >
        <div className="content1" style={{ width: "100%" }}>
          <div className="progress">
            {step1Percent !== null ? step1Percent.map((x, i) => {
              return (
                i == 0 ?
                <div className={x.value === '100.00' ?
                "progress-bar bg-white progress-bar-stripped curved":
                "progress-bar bg-white progress-bar-stripped"} 
                style={{ height: x.value + '%' }}>
                  </div>
                :
                ( i===1?
                  <div className="progress-bar main-element"
                  style={{ height: x.value + "%" ,backgroundColor: x.color}}>
                    <span>{x.value >0 ? x.label:""}</span>
                    <div className='progress-bar content-element' style={{  height: 'calc(100%)',backgroundColor: x.color }}> 
                    </div>
                </div>
                  :
                  <div className="progress-bar main-element"
                  style={{ height: x.value + "%",backgroundColor: x.color }}>
                    <span>{x.value >0 ? x.label:""}</span>
                    <div  className="progress-bar space-element" style={{ height: '4px', backgroundColor: "#FFFFFF !important" }}>
                    </div>
                    <div className='progress-bar content-element' style={{ height: 'calc(100% - 4px)',backgroundColor: x.color }}>
                    </div>
                </div>
                )
              )
            }) : null}
          </div>
          {/* <div className="progress-content">
            <span className="content2">Estimated spends on school/year</span>
          </div> */}
        </div>
        <div className="content2" style={{ width: "100%" }}>
          <div className="progress">
            { percentList !== null ? percentList.map((x, i) => {
              return (
                i === 0 ?
                <div className={x.value === '100.00' ?
                "progress-bar bg-white progress-bar-stripped curved":
                "progress-bar bg-white progress-bar-stripped"} 
                style={{ height: x.value + '%' }}>
                  </div>
                  :
                  ( i===1?
                    <div className="progress-bar main-element"
                    style={{ height: x.value + "%" ,backgroundColor: x.color}}>
                      <span>{x.value >0 ? x.label:""}</span>
                      <div className='progress-bar content-element' style={{  height: 'calc(100%)',backgroundColor: x.color }}> 
                      </div>
                  </div>
                    :
                    <div className="progress-bar main-element"
                    style={{ height: x.value + "%",backgroundColor: x.color }}>
                      <span>{x.value >0 ? x.label:""}</span>
                      <div  className="progress-bar space-element" style={{ height: '4px', backgroundColor: "#FFFFFF !important" }}>
                      </div>
                      <div className='progress-bar content-element' style={{ height: 'calc(100% - 4px)',backgroundColor: x.color }}>
                      </div>
                  </div>
                  )
              )
            }) : null}
          </div>
          {/* <div className="progress-content">
            <span className="content2">Estimated spends on other expenses</span>
          </div> */}
        </div>
      </div>
    </div>
  )
}