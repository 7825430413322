import React, { useState } from 'react'
import { useQuery } from "@apollo/client"
import { GET_STUDENT_BUDGET_PARAMETER_VALUES } from "../../components/graphql"

export default function Foods_Drinks() {
  const [limitValue,setLimitValue] = useState(0.0)
  const [mealsCost, setMealsCost] = useState(0.0)
  const [drinksCost, setDrinksCost] = useState(0.0)
  const [step1Percent, setStep1Percent] = useState([])
  const [percentList, setPercentList] = useState([]);
  const [percentList1,setPercentList1] = useState([]);
  const [mealsType, setMealsType] = useState([]);
  const [mealsTypeCost, setMealsTypeCost] = useState([]);
  const [drinksType, setDrinksType] = useState([]);
  const [drinksTypeCost, setDrinksTypeCost] = useState([]);
  const getStudentBudgetParameter = useQuery(GET_STUDENT_BUDGET_PARAMETER_VALUES, {
    variables: { id: sessionStorage.getItem('housingCitySelectedID') },
  });

  React.useEffect(() => {
    console.log("foods reloadedddddd333")
    sessionStorage.setItem('activeStep',2)
    setLimitValue(sessionStorage.getItem('limitValue')?sessionStorage.getItem('limitValue'):0)

    setStep1Percent(JSON.parse(sessionStorage.getItem('step1Percent')) ? JSON.parse(sessionStorage.getItem('step1Percent')) : [])
    setPercentList(JSON.parse(sessionStorage.getItem('step2Percent')) ? JSON.parse(sessionStorage.getItem('step2Percent')) : [])
    setPercentList1(JSON.parse(sessionStorage.getItem('step3Percent')) ? JSON.parse(sessionStorage.getItem('step3Percent')) : JSON.parse(sessionStorage.getItem('step2Percent')))

    setMealsType(JSON.parse(sessionStorage.getItem('mealsType')) ?JSON.parse(sessionStorage.getItem('mealsType')):[])
    setMealsTypeCost(JSON.parse(sessionStorage.getItem('mealsTypeCost')) ?JSON.parse(sessionStorage.getItem('mealsTypeCost')):[])
    setDrinksType(JSON.parse(sessionStorage.getItem('drinksType')) ? JSON.parse(sessionStorage.getItem('drinksType')):[])
    setDrinksTypeCost(JSON.parse(sessionStorage.getItem('drinksTypeCost')) ?JSON.parse(sessionStorage.getItem('drinksTypeCost')):[])
    
    setMealsCost(sessionStorage.getItem('mealsCost') ?sessionStorage.getItem('mealsCost'):0.0)
    setDrinksCost(sessionStorage.getItem('drinksCost') ?sessionStorage.getItem('drinksCost'):0.0)
  }, []);

  React.useEffect(() => {
    callPercentFill();
    return () => {
      console.log("unmount food");
      sessionStorage.setItem('mealsType',  JSON.stringify(mealsType));
      sessionStorage.setItem('mealsTypeCost',  JSON.stringify(mealsTypeCost));
      
      sessionStorage.setItem('drinksType',  JSON.stringify(drinksType));
      sessionStorage.setItem('drinksTypeCost',  JSON.stringify(drinksTypeCost));
      
      sessionStorage.setItem('mealsCost', mealsCost);
      sessionStorage.setItem('drinksCost', drinksCost);
    }
  }, [mealsType,mealsTypeCost,drinksType,drinksTypeCost,mealsCost,drinksCost]);

  React.useEffect(() => {
    return () => {
      sessionStorage.setItem('limitValue',limitValue)
      sessionStorage.setItem('step2Percent', JSON.stringify(percentList))
      sessionStorage.setItem('step3Percent',JSON.stringify(percentList1))
    }
  }, [percentList,limitValue,percentList1]);

  const callPercentFill = () => {
    var listValue =[]
    var listValue1 = ([...percentList].reverse()).slice(0, -1)
    var listValue2=[]
    var maximumLimitTemp = 10000;
    var valueTemp = 0.0;
    listValue1.map((x)=>{
      maximumLimitTemp = maximumLimitTemp - parseFloat(x.amount);
      valueTemp = parseFloat(valueTemp) + parseFloat(x.value)
    })
    var tempAmount = parseFloat(mealsCost?mealsCost:0)+parseFloat(drinksCost?drinksCost:0)
    if(maximumLimitTemp>0){
      if(parseFloat(tempAmount)>0){
        let tempValue = 0;
        tempValue = parseFloat(tempAmount / maximumLimitTemp) * 100;
        if((parseFloat(valueTemp)+parseFloat(tempValue) > parseFloat(100) ?100-parseFloat(valueTemp):parseFloat(tempValue).toFixed(2))>0){
          listValue.push({
              'label': 'Meals & Drinks',
              'value': (parseFloat(valueTemp)+parseFloat(tempValue) > parseFloat(100) ?100-parseFloat(valueTemp):parseFloat(tempValue).toFixed(2)),
              'amount': tempAmount? (parseFloat(maximumLimitTemp) -parseFloat(tempAmount) <=0?parseFloat(maximumLimitTemp):tempAmount):0,
              'color':''
          });
          valueTemp = valueTemp + parseFloat(listValue[0]?listValue[0].value:0);
          maximumLimitTemp = parseFloat(maximumLimitTemp) - parseFloat(listValue[0].amount? (parseFloat(maximumLimitTemp) -parseFloat(listValue[0].amount) <=0?parseFloat(maximumLimitTemp):listValue[0].amount):0);
        }
      }
    }
    listValue2=[...[...listValue1,...listValue]]
    valueTemp = 0.0;
    var expenseColorList = [
      '#35DF90','#0055A4','#FFD859','#F47961',
      '#35DF90','#0055A4','#FFD859','#F47961',
      '#35DF90','#0055A4','#FFD859','#F47961',
      '#35DF90','#0055A4','#FFD859','#F47961',
      '#35DF90','#0055A4','#FFD859','#F47961']
    var expenseColorListInit = ['#35DF90','#0055A4','#FFD859','#F47961']
    listValue2.map((x,i)=>{
      valueTemp = parseFloat(valueTemp) + parseFloat(x.value)
      if(i<20){
        x.color = expenseColorList[i]
      }
      else{
        x.color = expenseColorListInit[Math.floor(Math.random() * expenseColorListInit.length)]
      }
    })
    listValue2.push({
      'label':"",        
      'value':parseFloat((100 - parseFloat(valueTemp)) > 0 ? 100 - parseFloat(valueTemp) : 0).toFixed(2),
      'amount' : 0,
      'color':'#FFFFFF'
    })
    setPercentList1(listValue2.reverse());
    setLimitValue(maximumLimitTemp)
  }

  const handleChangeQty = (e, index, length, cost, type) => {
    var tempValue = type === "meals" ? [...mealsType] : [...drinksType]
    var tempCostValue = type === "meals" ? [...mealsTypeCost] : [...drinksTypeCost]
    if (tempValue.length === 0) {
      tempValue = Array(length).fill(0)
      type === "meals" ? setMealsType(tempValue) : setDrinksType(tempValue)
    }
    if (tempCostValue.length === 0) {
      tempCostValue = Array(length).fill(0)
      type === "meals" ? setMealsTypeCost(tempCostValue) : setDrinksTypeCost(tempCostValue)
    }
    tempCostValue[index] = cost
    tempValue[index] = e >= 0 ? e : 0
    type === "meals" ? setMealsType(tempValue) : setDrinksType(tempValue)
    type === "meals" ? setMealsTypeCost(tempCostValue) : setDrinksTypeCost(tempCostValue)
    var mealCostTemp = 0.0;
    tempValue.map((x, index) => {
      mealCostTemp = mealCostTemp + (tempValue[index] * tempCostValue[index])
    })
    type === "meals" ? setMealsCost(mealCostTemp) : setDrinksCost(mealCostTemp)
  }
  const handleChangeQty1 = (e, index, operation, length, cost, type) => {
    var tempValue = type === "meals" ? [...mealsType] : [...drinksType]
    var tempCostValue = type === "meals" ? [...mealsTypeCost] : [...drinksTypeCost]
    if (tempValue.length === 0) {
      tempValue = Array(length).fill(0)
      type === "meals" ? setMealsType(tempValue) : setDrinksType(tempValue)
    }
    if (tempCostValue.length === 0) {
      tempCostValue = Array(length).fill(0)
      type === "meals" ? setMealsTypeCost(tempCostValue) : setDrinksType(tempCostValue)
    }
    if (operation === '+') {
      tempValue[index] = parseInt(tempValue[index]) + 1
    }
    else {
      tempValue[index] = tempValue[index] - 1 >= 0 ? tempValue[index] - 1 : 0
    }
    tempCostValue[index] = cost
    type === "meals" ? setMealsType(tempValue) : setDrinksType(tempValue)
    type === "meals" ? setMealsTypeCost(tempCostValue) : setDrinksTypeCost(tempCostValue)
    var mealCostTemp = 0.0;
    tempValue.map((x, index) => {
      mealCostTemp = mealCostTemp + (tempValue[index] * tempCostValue[index])
    })
    type === "meals" ? setMealsCost(mealCostTemp) : setDrinksCost(mealCostTemp)
  }
  return (
    <div className="food-step-form row">
      <div className="mainContent column">
        <div className='row pb20'>
          <h6 className="pb20">Meals &amp; Drinks</h6>
          <div className='six columns pb20'>
            <label className="pb20">What kind of a meal do you plan on having?<span className="red-star">* </span> (MONTHLY)</label>
            {getStudentBudgetParameter ?
              getStudentBudgetParameter.data ?
                getStudentBudgetParameter.data.city ?
                getStudentBudgetParameter.data.city.meals.length>1?
                  <div className='scroll-list'>
                    {getStudentBudgetParameter.data.city.meals.map((item, index) =>
                    (
                      <div className="meal-item">
                        <span className="meal-item-name">{item.mealType +"- € "+ item.mealPrice}</span>
                        <span className="meal-item-qty">
                          <i className="fa fa-solid fa-caret-left decreaseQty"
                            onClick={(e) =>
                              handleChangeQty1(mealsType[index],
                                index, "-",
                                getStudentBudgetParameter.data.city.meals.length,
                                item.mealPrice,
                                "meals")}></i>
                          <input type="text"
                            className="qtyValue"
                            placeholder='0'
                            value={mealsType[index]}
                            onChange={(e) =>
                              handleChangeQty(e.target.value, index,
                                getStudentBudgetParameter.data.city.meals.length,
                                item.mealPrice,
                                "meals")} />
                          <i className="fa fa-solid fa-caret-right increaseQty"
                            onClick={(e) =>
                              handleChangeQty1(mealsType[index],
                                index, "+",
                                getStudentBudgetParameter.data.city.meals.length,
                                item.mealPrice,
                                "meals")}></i>
                        </span>
                      </div>
                    ))}
                  </div>
                  :<span className='no-record'>No Records found</span> 
                  : <span className='no-record'>No Records found</span> 
                : <span className='no-record'>No Records found</span> 
              : <span className='no-record'>No Records found</span> }
          </div>
          <div className="results pb20">
            <span>
              <strong>Your Meals are expected to cost around:</strong> 
              <div className='price-section'>
                  <span className='currency-symbol'>€</span>
                  <input
                    className='u-full-width'
                    placeholder='0.0'
                    type='text'
                    readOnly={true}
                    onChange={e => setMealsCost(e.target.value)}
                    value={parseFloat(mealsCost).toFixed(2)}
                    style={{ border: 'none', borderBottom: "2px solid #808080" }}
                  />
              </div>
            </span>
          </div>
          <div className='six columns pt25 pb20'>
            <label className="pb20">What All drinks do you plan on consuming in a Month?<span className="red-star">* </span></label>
            {getStudentBudgetParameter ?
              getStudentBudgetParameter.data ?
                getStudentBudgetParameter.data.city ?
                getStudentBudgetParameter.data.city.drinks.length>1?
                  <div className='scroll-list'>
                    {getStudentBudgetParameter.data.city.drinks.map((item, index) =>
                    (
                      <div className="meal-item">
                        <span className="meal-item-name">{item.drinkType +"- € "+ item.drinkPrice}</span>
                        <span className="meal-item-qty">
                          <i className="fa fa-solid fa-caret-left decreaseQty"
                            onClick={(e) =>
                              handleChangeQty1(drinksType[index],
                                index, "-",
                                getStudentBudgetParameter.data.city.drinks.length, item.drinkPrice,
                                "drink")}>
                          </i>
                          <input type="text"
                            className="qtyValue"
                            placeholder='0'
                            value={drinksType[index]}
                            onChange={(e) =>
                              handleChangeQty(e.target.value,
                                index,
                                getStudentBudgetParameter.data.city.drinks.length,
                                item.drinkPrice,
                                "drink")} />
                          <i className="fa fa-solid fa-caret-right increaseQty"
                            onClick={(e) =>
                              handleChangeQty1(drinksType[index],
                                index, "+",
                                getStudentBudgetParameter.data.city.drinks.length,
                                item.drinkPrice,
                                "drink")}></i>
                        </span>
                      </div>
                    ))}
                  </div>
                  :<span className='no-record'>No Records found</span> 
                  : <span className='no-record'>No Records found</span>
                : <span className='no-record'>No Records found</span> 
              : <span className='no-record'>No Records found</span> }
          </div>
          <div className="results pb20">
            <span>
              <strong>Your Drinks are expected to cost around:</strong> 
              <div className='price-section'>
                  <span className='currency-symbol'>€</span>
                  <input
                  className='u-full-width'
                  placeholder='0.0'
                  type='text'
                  onChange={e => setDrinksCost(e.target.value)}
                  value={parseFloat(drinksCost).toFixed(2)}
                  readOnly={true}
                  style={{ border: 'none', borderBottom: "2px solid #808080" }}
                />
              </div>
            </span>
          </div>
        </div>
      </div>
      <div className="progressContent column" >
        <div className="content1" style={{ width: "100%" }}>
          <div className="progress">
            {step1Percent !== null ? step1Percent.map((x, i) => {
              return (
                i == 0 ?
                  <div className={x.value === '100.00' ?
                  "progress-bar bg-white progress-bar-stripped curved":
                  "progress-bar bg-white progress-bar-stripped"} 
                    style={{ height: x.value + '%' }}>
                  </div>
                :
                ( i===1?
                  <div className="progress-bar main-element"
                  style={{ height: x.value + "%" ,backgroundColor: x.color}}>
                    <span>{x.value >0 ? x.label:""}</span>
                    <div className='progress-bar content-element' style={{  height: 'calc(100%)',backgroundColor: x.color }}> 
                    </div>
                </div>
                  :
                  <div className="progress-bar main-element"
                  style={{ height: x.value + "%",backgroundColor: x.color }}>
                    <span>{x.value >0 ? x.label:""}</span>
                    <div  className="progress-bar space-element" style={{ height: '4px', backgroundColor: "#FFFFFF !important" }}>  
                    </div>
                    <div className='progress-bar content-element' style={{ height: 'calc(100% - 4px)',backgroundColor: x.color }}>
                    </div>
                </div>
                )
              )
            }) : null}
          </div>
          {/* <div className="progress-content">
            <span className="content2">Estimated spends on school/year</span>
          </div> */}
        </div>
        <div className="content2" style={{ width: "100%" }}>
          <div className="progress">
            {percentList1 !== null ? percentList1.map((x, i) => {
              return (
                i == 0 ?
                  <div className={x.value === '100.00' ?
                  "progress-bar bg-white progress-bar-stripped curved":
                  "progress-bar bg-white progress-bar-stripped"} 
                  style={{ height: x.value + '%' }}>
                  </div>
                  :
                  ( i===1?
                    <div className="progress-bar main-element"
                    style={{ height: x.value + "%" ,backgroundColor: x.color}}>
                      <span>{x.value >0 ? x.label:""}</span>
                      <div className='progress-bar content-element' style={{  height: 'calc(100%)',backgroundColor: x.color }}> 
                      </div>
                  </div>
                    :
                    <div className="progress-bar main-element"
                    style={{ height: x.value + "%",backgroundColor: x.color }}>
                      <span>{x.value >0 ? x.label:""}</span>
                      <div  className="progress-bar space-element" style={{ height: '4px', backgroundColor: "#FFFFFF !important" }}>
                      </div>
                      <div className='progress-bar content-element' style={{ height: 'calc(100% - 4px)',backgroundColor: x.color }}>
                      </div>
                  </div>
                  )
              )
            }) : null}
          </div>
          {/* <div className="progress-content">
            <span className="content2">Estimated spends on other expenses</span>
          </div> */}
        </div>
      </div>
    </div>
  )
}