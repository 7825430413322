import React, { useState } from "react"
import Layout from "../../components/layout"
import MultiStep from 'react-multistep'
import School from '../../components/budget-steps/school'
import Housing from '../../components/budget-steps/housing'
import Foods_Drinks from '../../components/budget-steps/foods_drinks'
import Expenses from '../../components/budget-steps/expenses'
import ExpensesNext from '../../components/budget-steps/expensesNext'
import Results from '../../components/budget-steps/results'
import "../../assets/css/tools.css"


const Tools = ({ data }) => {

	React.useEffect(() => {
		console.log("tools reloadedddddd",sessionStorage.getItem('userRedirectFlag'),"-----------")
		if(sessionStorage.getItem('userRedirectFlag') ===null){
			console.log("insideeeeeeeeeeeeeeeeee1123")
			sessionStorage.removeItem('housingCitySelectedID');
			sessionStorage.removeItem('cityName');
			sessionStorage.removeItem('schoolName');
			sessionStorage.removeItem('tuitionFee');
			sessionStorage.removeItem('extraAmountList');
	
			sessionStorage.removeItem('activeHousingID');
			sessionStorage.removeItem('activeHousingPrice');
			sessionStorage.removeItem('activeIndex');
			sessionStorage.removeItem('housingExtraAmountList');
	
			sessionStorage.removeItem('step1Percent');
			sessionStorage.removeItem('step2Percent');
			sessionStorage.removeItem('step3Percent');
			sessionStorage.removeItem('step4Percent');

			sessionStorage.removeItem('mealsCost');
			sessionStorage.removeItem('mealsType');
			sessionStorage.removeItem('mealsTypeCost');
	
			sessionStorage.removeItem('drinksCost');
			sessionStorage.removeItem('drinksType');
			sessionStorage.removeItem('drinksTypeCost');
	
			sessionStorage.removeItem('grocery');
			sessionStorage.removeItem('groceryCost');
			sessionStorage.removeItem('groceryTypeCost');
	
			sessionStorage.removeItem('personalCare');
			sessionStorage.removeItem('personalCareCost');
			sessionStorage.removeItem('personalCareTypeCost');
	
			sessionStorage.removeItem('transportation');
			sessionStorage.removeItem('transportationCost');
			sessionStorage.removeItem('transportationTypeCost');
	
			sessionStorage.removeItem('entertainment');
			sessionStorage.removeItem('entertainmentCost');
			sessionStorage.removeItem('entertainmentTypeCost');
			sessionStorage.removeItem('otherExpense');
		}
		else{
			console.log("out1111111")
		}
		return () => {
			console.log("unmount tools",sessionStorage.getItem('school'))
			sessionStorage.removeItem('cityName');
			sessionStorage.removeItem('housingCitySelectedID');
			sessionStorage.removeItem('schoolName');
			sessionStorage.removeItem('tuitionFee');
			sessionStorage.removeItem('extraAmountList');
		}
	  }, []);
	
	React.useEffect(() => {
		if (typeof window !== `undefined`) {
			if (typeof document !== `undefined`) {
			localStorage.setItem('lastRevistedPage',window.location.pathname)
			}
		}
	});
	const steps = [
		{ name: 'School', component: <School/>},
		{ name: 'Housing',component: <Housing />},
		{ name: 'Food & Drinks',component: <Foods_Drinks />},
		{ name: 'Expenses',component: <Expenses />},
		{name : 'ExpensesNext',component:<ExpensesNext/>},
		{ name: 'Results',component: typeof window !== "undefined" ?(localStorage.getItem("userId") ? <Results />:<ExpensesNext/>):<ExpensesNext/>}
	]
	
	const prevStyle = { background: '#33c3f0' }
	const nextStyle = { background: '#33c3f0' }
	return(
		<>
			<Layout setTitle={"Tools"} tag="tools">
				<div className="App">
					{(typeof window !== "undefined")?
						<div className={`container`}>
							<h3>Student Budget Calculator</h3>
							<span className="main-para">
							<p>The student budget calculator is much more than just a tool. It not only gives you an estimated 
								overall cost per month but it also breaks down the cost to the most simplest categories based 
								upon your inputs so that you can plan better and save as much money as possible. Get your 
								expenses under control right away.</p>
							</span>
							<div className="StepperContainer" style={{marginTop:"50px"}}>
								<MultiStep className={`steps`} activeStep={sessionStorage.getItem('activeStep')} userlogin={localStorage.getItem("userId") ? true:false} showNavigation={true} steps={steps} prevStyle={prevStyle} nextStyle={nextStyle} />
							</div>
						</div>
					:""}
				</div>
			</Layout>
		</>
	)
}
export default Tools
