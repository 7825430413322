import React, { useState } from 'react'
import { useQuery } from "@apollo/client"
import { GET_STUDENT_BUDGET_PARAMETER_VALUES } from "../../components/graphql"

export default function Expenses() {
  const [limitValue,setLimitValue] = useState(0.0)
  const [step1Percent, setStep1Percent] = useState([])
  const [percentList, setPercentList] = useState([]);
  const [percentList1, setPercentList1] = useState([]);
  const [grocery, setGrocery] = useState([]);
  const [transportation, setTransportation] = useState([]);
  const [personalCare, setPersonalCare] = useState([]);
  const [entertainment, setEntertainment] = useState([]);
  const [groceryTypeCost, setGroceryTypeCost] = useState([]);
  const [transportationTypeCost, setTransportationTypeCost] = useState([]);
  const [personalCareTypeCost, setPersonalCareTypeCost] = useState([]);
  const [entertainmentTypeCost, setEntertainmentTypeCost] = useState([]);
  const [groceryCost, setGroceryCost] = useState(0.0);
  const [transportationCost, setTransportationCost] = useState(0.0);
  const [personalCareCost, setPersonalCareCost] = useState(0.0);
  const [entertainmentCost, setEntertainmentCost] = useState(0.0);
  const [extraExpense,setExtraExpense]= useState([{ expenseName: "", expenseAmount: "" }])

  React.useEffect(() => {
    console.log("expenses reloadedddddd333")
    sessionStorage.setItem('activeStep',3);
    setStep1Percent(JSON.parse(sessionStorage.getItem('step1Percent')) ? JSON.parse(sessionStorage.getItem('step1Percent')) : [])
    setPercentList(JSON.parse(sessionStorage.getItem('step3Percent')) ? JSON.parse(sessionStorage.getItem('step3Percent')) : [])
    setPercentList1(JSON.parse(sessionStorage.getItem('step4Percent')) ? JSON.parse(sessionStorage.getItem('step4Percent')) : [])
    setLimitValue(sessionStorage.getItem('limitValue')?sessionStorage.getItem('limitValue'):0)
    setGrocery(JSON.parse(sessionStorage.getItem('grocery')) ? JSON.parse(sessionStorage.getItem('grocery')) : [])
    setTransportation(JSON.parse(sessionStorage.getItem('transportation')) ? JSON.parse(sessionStorage.getItem('transportation')) : [])
    setPersonalCare(JSON.parse(sessionStorage.getItem('personalCare')) ? JSON.parse(sessionStorage.getItem('personalCare')) : [])
    setEntertainment(JSON.parse(sessionStorage.getItem('entertainment')) ? JSON.parse(sessionStorage.getItem('entertainment')) : [])
    
    setGroceryTypeCost(JSON.parse(sessionStorage.getItem('groceryTypeCost')) ? JSON.parse(sessionStorage.getItem('groceryTypeCost')) : [])
    setTransportationTypeCost(JSON.parse(sessionStorage.getItem('transportationTypeCost')) ? JSON.parse(sessionStorage.getItem('transportationTypeCost')) : [])
    setPersonalCareTypeCost(JSON.parse(sessionStorage.getItem('personalCareTypeCost')) ? JSON.parse(sessionStorage.getItem('personalCareTypeCost')) : [])
    setEntertainmentTypeCost(JSON.parse(sessionStorage.getItem('entertainmentTypeCost')) ? JSON.parse(sessionStorage.getItem('entertainmentTypeCost')) : [])
    
    setGroceryCost(JSON.parse(sessionStorage.getItem('groceryCost')) ? JSON.parse(sessionStorage.getItem('groceryCost')) : 0.0)
    setTransportationCost(JSON.parse(sessionStorage.getItem('transportationCost')) ? JSON.parse(sessionStorage.getItem('transportationCost')) : 0.0)
    setPersonalCareCost(JSON.parse(sessionStorage.getItem('personalCareCost')) ? JSON.parse(sessionStorage.getItem('personalCareCost')) : 0.0)
    setEntertainmentCost(JSON.parse(sessionStorage.getItem('entertainmentCost')) ? JSON.parse(sessionStorage.getItem('entertainmentCost')) : 0.0)
    setExtraExpense(JSON.parse(sessionStorage.getItem('otherExpense')) ? JSON.parse(sessionStorage.getItem('otherExpense')) : [{ expenseName: "", expenseAmount: "" }])
  }, []);

  React.useEffect(() => {
    callPercentFill();
    return () => {
      console.log("unmount expenses")
      sessionStorage.setItem('grocery',  JSON.stringify(grocery));
      sessionStorage.setItem('transportation',  JSON.stringify(transportation));
      sessionStorage.setItem('personalCare',  JSON.stringify(personalCare));
      sessionStorage.setItem('transportation',  JSON.stringify(transportation));
      sessionStorage.setItem('entertainment', JSON.stringify(entertainment));

      sessionStorage.setItem('groceryTypeCost',  JSON.stringify(groceryTypeCost));
      sessionStorage.setItem('transportationTypeCost',  JSON.stringify(transportationTypeCost));
      sessionStorage.setItem('personalCareTypeCost',  JSON.stringify(personalCareTypeCost));
      sessionStorage.setItem('entertainmentTypeCost',  JSON.stringify(entertainmentTypeCost));

      sessionStorage.setItem('groceryCost',  JSON.stringify(groceryCost));
      sessionStorage.setItem('transportationCost',  JSON.stringify(transportationCost));
      sessionStorage.setItem('personalCareCost',  JSON.stringify(personalCareCost));
      sessionStorage.setItem('entertainmentCost',  JSON.stringify(entertainmentCost));
      sessionStorage.setItem('otherExpense',JSON.stringify(extraExpense))
    }
  }, [grocery,transportation,personalCare,transportation,
      groceryTypeCost,transportationTypeCost,personalCareTypeCost,entertainmentTypeCost,
      groceryCost,transportationCost,personalCareCost,entertainmentCost,extraExpense]);

  React.useEffect(() => {
    // console.log("percentListtttttttttt",percentList)
    return () => {
      sessionStorage.setItem('limitValue',limitValue)
      sessionStorage.setItem('step3Percent', JSON.stringify(percentList))
      sessionStorage.setItem('step4Percent', JSON.stringify(percentList1))
    }
  }, [percentList,percentList1,limitValue]);

  const getStudentBudgetParameter = useQuery(GET_STUDENT_BUDGET_PARAMETER_VALUES, {
    variables: { id: sessionStorage.getItem('housingCitySelectedID') },
  });

  const handleChangeQty = (e, index, length, cost, type) => {
    var tempValue = type === "grocery" ? [...grocery] 
      : (type === "transportation" ? [...transportation]
      :(type === "personal_cares" ? [...personalCare]:[...entertainment]))
    var tempCostValue = type === "grocery" ? [...groceryTypeCost] 
    : (type === "transportation" ? [...transportationTypeCost]
    :(type === "personal_cares" ? [...personalCareTypeCost]:[...entertainmentTypeCost]))

    if (tempValue.length === 0) {
      tempValue = Array(length).fill(0)
      type === "grocery" ? setGrocery(tempValue) 
        : (type === "transportation" ? setTransportation(tempValue)
        :(type === "personal_cares" ? setPersonalCare(tempValue):setEntertainment(tempValue)))
    }
    if (tempCostValue.length === 0) {
      tempCostValue = Array(length).fill(0)
      type === "grocery" ? setGroceryTypeCost(tempCostValue) 
        : (type === "transportation" ? setTransportationTypeCost(tempCostValue)
        :(type === "personal_cares" ? setPersonalCareTypeCost(tempCostValue):setEntertainmentTypeCost(tempCostValue)))
    }
    tempCostValue[index] = cost
    tempValue[index] = e >= 0 ? e : 0
    type === "grocery" ? setGrocery(tempValue) 
        : (type === "transportation" ? setTransportation(tempValue)
        :(type === "personal_cares" ? setPersonalCare(tempValue):setEntertainment(tempValue)))
    type === "grocery" ? setGroceryTypeCost(tempCostValue) 
        : (type === "transportation" ? setTransportationTypeCost(tempCostValue)
        :(type === "personal_cares" ? setPersonalCareTypeCost(tempCostValue):setEntertainmentTypeCost(tempCostValue)))
    var mealCostTemp = 0.0;
    tempValue.map((x, index) => {
      mealCostTemp = mealCostTemp + (tempValue[index] * tempCostValue[index])
    })
    type === "grocery" ? setGroceryCost(mealCostTemp) 
        : (type === "transportation" ? setTransportationCost(mealCostTemp)
        :(type === "personal_cares" ? setPersonalCareCost(mealCostTemp):setEntertainmentCost(mealCostTemp)))
  }
  
  const handleChangeQty1 = (e, index, operation, length, cost, type) => {
    var tempValue = type === "grocery" ? [...grocery] 
    : (type === "transportation" ? [...transportation]
    :(type === "personal_cares" ? [...personalCare]:[...entertainment]))
    var tempCostValue = type === "grocery" ? [...groceryTypeCost] 
    : (type === "transportation" ? [...transportationTypeCost]
    :(type === "personal_cares" ? [...personalCareTypeCost]:[...entertainmentTypeCost]))

    if (tempValue.length === 0) {
      tempValue = Array(length).fill(0)
      type === "grocery" ? setGrocery(tempValue) 
        : (type === "transportation" ? setTransportation(tempValue)
        :(type === "personal_cares" ? setPersonalCare(tempValue):setEntertainment(tempValue)))
    }
    if (tempCostValue.length === 0) {
      tempCostValue = Array(length).fill(0)
      type === "grocery" ? setGroceryTypeCost(tempCostValue) 
        : (type === "transportation" ? setTransportationTypeCost(tempCostValue)
        :(type === "personal_cares" ? setPersonalCareTypeCost(tempCostValue):setEntertainmentTypeCost(tempCostValue)))
    }
    if (operation === '+') {
      tempValue[index] = parseInt(tempValue[index]) + 1
    }
    else {
      tempValue[index] = tempValue[index] - 1 >= 0 ? tempValue[index] - 1 : 0
    }
    tempCostValue[index] = cost
    type === "grocery" ? setGrocery(tempValue) 
        : (type === "transportation" ? setTransportation(tempValue)
        :(type === "personal_cares" ? setPersonalCare(tempValue):setEntertainment(tempValue)))
    type === "grocery" ? setGroceryTypeCost(tempCostValue) 
        : (type === "transportation" ? setTransportationTypeCost(tempCostValue)
        :(type === "personal_cares" ? setPersonalCareTypeCost(tempCostValue):setEntertainmentTypeCost(tempCostValue)))
    var mealCostTemp = 0.0;
    tempValue.map((x, index) => {
      mealCostTemp = mealCostTemp + (tempValue[index] * tempCostValue[index])
    })
    type === "grocery" ? setGroceryCost(mealCostTemp) 
        : (type === "transportation" ? setTransportationCost(mealCostTemp)
        :(type === "personal_cares" ? setPersonalCareCost(mealCostTemp):setEntertainmentCost(mealCostTemp)))
  }

  const handleExtraAmountChange = (e, index) => {
    const { name, value } = e.target;
    const list = [...extraExpense];
    list[index][name] = value;
    setExtraExpense(list);
  };

  const handleRemoveClick = index => {
    const list = [...extraExpense];
    list.splice(index, 1);
    setExtraExpense(list);
  };

  const handleAddClick = () => {
    setExtraExpense([...extraExpense, { expenseName: "", expenseAmount: "" }]);
  };

  const callPercentFill = () => {
    var listValue =[]
    var listValue1 = ([...percentList].reverse()).slice(0, -1)
    var listValue2=[]
    var maximumLimitTemp = 10000;
    console.log("Expensessss-->>",limitValue,maximumLimitTemp)
    var valueTemp = 0.0;
    listValue1.map((x)=>{
        maximumLimitTemp = maximumLimitTemp - parseFloat(x.amount);
        valueTemp = parseFloat(valueTemp) + parseFloat(x.value)
        console.log("old -----",maximumLimitTemp,"-----",valueTemp)
      })
    console.log("1111111",maximumLimitTemp,"111111111",valueTemp)
    var tempAmount = (groceryCost ? groceryCost :0) +
                    (transportationCost? transportationCost :0) +
                    (personalCareCost ? personalCareCost: 0) +
                    (entertainmentCost ? entertainmentCost :0)
    let tempValue = 0;
    tempValue = parseFloat(tempAmount / maximumLimitTemp) * 100;
    if((parseFloat(valueTemp)+parseFloat(tempValue) > parseFloat(100) ?100-parseFloat(valueTemp):parseFloat(tempValue).toFixed(2))>0){
      listValue.push({
        'label':'Expenses',
        'value': (parseFloat(valueTemp)+parseFloat(tempValue) > parseFloat(100) ?100-parseFloat(valueTemp):parseFloat(tempValue).toFixed(2)),
        'amount': tempAmount? (parseFloat(maximumLimitTemp) -parseFloat(tempAmount) <=0?parseFloat(maximumLimitTemp):tempAmount):0,
        'color':'#FFD859'
      });
      valueTemp = valueTemp + parseFloat(listValue[0]?listValue[0].value:0);
      maximumLimitTemp = parseFloat(maximumLimitTemp) - parseFloat(listValue[0].amount? (parseFloat(maximumLimitTemp) -parseFloat(listValue[0].amount) <=0?parseFloat(maximumLimitTemp):listValue[0].amount):0);
    }
    if (extraExpense.length > 0) {
      extraExpense.map((x, i) => {
        if(parseFloat(x.expenseAmount)>0){
          tempValue = 0;
          tempValue = ( (parseFloat(x.expenseAmount)>0?x.expenseAmount:0) / maximumLimitTemp) * 100;
          if(parseFloat(tempValue)<=100 && parseFloat(maximumLimitTemp) >0){ 
            if((parseFloat(valueTemp)+parseFloat(tempValue) > parseFloat(100) ?100-parseFloat(valueTemp):parseFloat(tempValue).toFixed(2))>0){
              listValue.push({
                  'label':x.expenseName,
                  'value':(parseFloat(valueTemp)+parseFloat(tempValue) > parseFloat(100) ?100-parseFloat(valueTemp):parseFloat(tempValue).toFixed(2)),
                  'amount': x.expenseAmount? (parseFloat(maximumLimitTemp) -parseFloat(x.expenseAmount) <=0?parseFloat(maximumLimitTemp):x.expenseAmount):0,
                  'color':""
              });
              valueTemp = valueTemp + parseFloat(listValue[i+1]?listValue[i+1].value:0);
              maximumLimitTemp = maximumLimitTemp - x.expenseAmount;
            }
          }
        }
      })
    }
    listValue2=[...[...listValue1,...listValue]]
    valueTemp = 0.0;
    var expenseColorList = [
      '#35DF90','#0055A4','#FFD859','#F47961',
      '#35DF90','#0055A4','#FFD859','#F47961',
      '#35DF90','#0055A4','#FFD859','#F47961',
      '#35DF90','#0055A4','#FFD859','#F47961',
      '#35DF90','#0055A4','#FFD859','#F47961']
    var expenseColorListInit = ['#35DF90','#0055A4','#FFD859','#F47961']
    listValue2.map((x,i)=>{
      valueTemp = valueTemp + parseFloat(x.value)
      if(i<20){
        x.color = expenseColorList[i]
      }
      else{
        x.color = expenseColorListInit[Math.floor(Math.random() * expenseColorListInit.length)]
      }
    })
    listValue2.push({
      'label':"",        
      'value':parseFloat((100 - parseFloat(valueTemp)) > 0 ? 100 - parseFloat(valueTemp) : 0).toFixed(2),
      'amount' : 0,
      'color':'#FFFFFF'
    })
    setPercentList1(listValue2.reverse());
    setLimitValue(maximumLimitTemp)
  }
  return (
    <div className="expenses-step-form row">
      <div className="mainContent column">
        <div className='row pb20'>
          <h6 className='pb20'>Expenses</h6>
          <div className='six columns pb20'>
            <label className="pb15">Your Grocery list may include (MONTHLY)<span className="red-star">*</span></label>
            {getStudentBudgetParameter ?
              getStudentBudgetParameter.data ?
                getStudentBudgetParameter.data.city ?
                getStudentBudgetParameter.data.city.groceries.length>1?
                  <div className='scroll-list'>
                    {getStudentBudgetParameter.data.city.groceries.map((item, index) =>
                    (
                      <div className="meal-item">
                        <span className="meal-item-name">{item.groceryItemName +"- € "+item.groceryItemPrice}</span>
                        <span className="meal-item-qty">
                          <i className="fa fa-solid fa-caret-left decreaseQty" onClick={(e) =>
                            handleChangeQty1(grocery[index],
                              index, "-",
                              getStudentBudgetParameter.data.city.groceries.length,
                              item.groceryItemPrice,
                              "grocery")}></i>
                          <input type="text" 
                            className="qtyValue" 
                            placeholder='0'
                            value={grocery[index]}
                            onChange={(e) =>
                              handleChangeQty(e.target.value, index,
                                getStudentBudgetParameter.data.city.groceries.length,
                                item.groceryItemPrice,
                                "grocery")} />
                          <i className="fa fa-solid fa-caret-right increaseQty" onClick={(e) =>
                            handleChangeQty1(grocery[index],
                              index, "+",
                              getStudentBudgetParameter.data.city.groceries.length,
                              item.groceryItemPrice,
                              "grocery")}></i>
                        </span>
                      </div>
                    ))}
                  </div>
                  :<span className='no-record'>No Records found</span>
                  : <span className='no-record'>No Records found</span>
                : <span className='no-record'>No Records found</span>
              : <span className='no-record'>No Records found</span>}
          </div>
          <div className='six columns pb20 pt15'>
            <label className="pb15">Your mode of transportation may include (MONTHLY)<span className="red-star">*</span></label>
            {getStudentBudgetParameter ?
              getStudentBudgetParameter.data ?
                getStudentBudgetParameter.data.city ?
                getStudentBudgetParameter.data.city.transportation.length?
                  <div className='scroll-list'>
                    {getStudentBudgetParameter.data.city.transportation.map((item, index) =>
                    (
                      <div className="meal-item">
                        <span className="meal-item-name">{item.transportationType +"- € "+ item.transportationCost}</span>
                        <span className="meal-item-qty">
                          <i className="fa fa-solid fa-caret-left decreaseQty" onClick={(e) =>
                            handleChangeQty1(transportation[index],
                              index, "-",
                              getStudentBudgetParameter.data.city.transportation.length,
                              item.transportationCost,
                              "transportation")}></i>
                          <input 
                            type="transportationtext" 
                            className="qtyValue" 
                            placeholder='0'
                            value={transportation[index]}
                            onChange={(e) =>
                              handleChangeQty(e.target.value, index,
                                getStudentBudgetParameter.data.city.transportation.length,
                                item.transportationCost,
                                "transportation")} />
                          <i className="fa fa-solid fa-caret-right increaseQty" onClick={(e) =>
                            handleChangeQty1(transportation[index],
                              index, "+",
                              getStudentBudgetParameter.data.city.transportation.length,
                              item.transportationCost,
                              "transportation")}></i>
                        </span>
                      </div>
                    ))}
                  </div>
                  : <span className='no-record'>No Records found</span>
                  : <span className='no-record'>No Records found</span>
                : <span className='no-record'>No Records found</span>
              : <span className='no-record'>No Records found</span>}
          </div>
          <div className='six columns  pb20 pt15'>
            <label className="pb15">Your Personal Expenses may include (MONTHLY)<span className="red-star">*</span></label>
            {getStudentBudgetParameter ?
              getStudentBudgetParameter.data ?
                getStudentBudgetParameter.data.city ?
                getStudentBudgetParameter.data.city.personal_cares.length>1?
                  <div className='scroll-list'>
                    {getStudentBudgetParameter.data.city.personal_cares.map((item, index) =>
                    (
                      <div className="meal-item">
                        <span className="meal-item-name">{item.itemName +"- € "+ item.itemCost}</span>
                        <span className="meal-item-qty">
                          <i className="fa fa-solid fa-caret-left decreaseQty" onClick={(e) =>
                            handleChangeQty1(personalCare[index],
                              index, "-",
                              getStudentBudgetParameter.data.city.personal_cares.length,
                              item.itemCost,
                              "personal_cares")}></i>
                          <input type="text" 
                            className="qtyValue" 
                            placeholder="0"
                            value={personalCare[index]}
                            onChange={(e) =>
                              handleChangeQty(e.target.value, index,
                                getStudentBudgetParameter.data.city.personal_cares.length,
                                item.itemCost,
                                "personal_cares")} />
                          <i className="fa fa-solid fa-caret-right increaseQty" onClick={(e) =>
                            handleChangeQty1(personalCare[index],
                              index, "+",
                              getStudentBudgetParameter.data.city.personal_cares.length,
                              item.itemCost,
                              "personal_cares")}></i>
                        </span>
                      </div>
                    ))}
                  </div>
                  :<span className='no-record'>No Records found</span>
                  : <span className='no-record'>No Records found</span>
                : <span className='no-record'>No Records found</span>
              : <span className='no-record'>No Records found</span>}
          </div>
          <div className='six columns pb20 pt15'>
            <label className="pb15">Your Entertainment expense can include (MONTHLY)<span className="red-star">*</span></label>
            {getStudentBudgetParameter ?
              getStudentBudgetParameter.data ?
                getStudentBudgetParameter.data.city ?
                getStudentBudgetParameter.data.city.entertainments.length>1?
                  <div className='scroll-list'>
                    {getStudentBudgetParameter.data.city.entertainments.map((item, index) =>
                    (
                      <div className="meal-item">
                        <span className="meal-item-name">{item.entertainmentType +"- € "+item.entertainmentCost}</span>
                        <span className="meal-item-qty">
                          <i className="fa fa-solid fa-caret-left decreaseQty" onClick={(e) =>
                            handleChangeQty1(entertainment[index],
                              index, "-",
                              getStudentBudgetParameter.data.city.entertainments.length,
                              item.entertainmentCost,
                              "entertainment")}></i>
                          <input 
                            type="text" 
                            className="qtyValue" 
                            placeholder='0'
                            value={entertainment[index]}
                            onChange={(e) =>
                              handleChangeQty(e.target.value, index,
                                getStudentBudgetParameter.data.city.entertainments.length,
                                item.entertainmentCost,
                                "entertainment")} />
                          <i className="fa fa-solid fa-caret-right increaseQty" onClick={(e) =>
                            handleChangeQty1(entertainment[index],
                              index, "+",
                              getStudentBudgetParameter.data.city.entertainments.length,
                              item.entertainmentCost,
                              "entertainment")}></i>
                        </span>
                      </div>
                    ))}
                  </div>
                  : <span className='no-record'>No Records found</span>
                  : <span className='no-record'>No Records found</span>
                : <span className='no-record'>No Records found</span>
              : <span className='no-record'>No Records found</span>}
          </div>
          <div className='six columns pb20 pt15 other-changes'>
            <label>Add any extra amount spent: </label>
            {extraExpense.map((x, i) => {
              return (
                <div className="box">
                  <div className="double-input">
                    <input
                      name="expenseName"
                      placeholder="Enter expense name"
                      value={x.expenseName}
                      onChange={e => handleExtraAmountChange(e, i)}
                      style={{ border: 'none', borderBottom: "2px solid #808080" }}
                    />
                    <div className='price-section'>
                      <span className='currency-symbol'>€</span>
                      <input
                        className="ml10"
                        name="expenseAmount"
                        placeholder="Enter Amount"
                        value={x.expenseAmount}
                        style={{ border: 'none', borderBottom: "2px solid #808080" }}
                        onChange={e => handleExtraAmountChange(e, i)}
                      />
                      </div>
                  </div>
                  <div className="btn-box mb-15">
                    {extraExpense.length !== 1 && <button
                      className="mr10 remove-button-custom"
                      onClick={() => handleRemoveClick(i)}>Remove</button>}
                    {extraExpense.length - 1 === i && <button className="add-button-custom" onClick={handleAddClick}>Add expense(click here)</button>}
                  </div>
                </div>
              );
            })}

          </div>
        </div>
      </div>
      <div className="progressContent column" >
        <div className="content1" style={{ width: "100%" }}>
          <div className="progress">
            {step1Percent !== null ? step1Percent.map((x, i) => {
              return (
                i == 0 ?
                  <div className={x.value === '100.00' ?
                    "progress-bar bg-white progress-bar-stripped curved":
                    "progress-bar bg-white progress-bar-stripped"} 
                    style={{ height: x.value + '%' }}>
                  </div>
                  :
                  ( i===1?
                    <div className="progress-bar main-element"
                    style={{ height: x.value + "%" ,backgroundColor: x.color}}>
                      <span>{x.value >0 ? x.label :""}</span>
                      <div className='progress-bar content-element' style={{  height: 'calc(100%)',backgroundColor: x.color }}> 
                      </div>
                  </div>
                    :
                    <div className="progress-bar main-element"
                    style={{ height: x.value + "%",backgroundColor: x.color }}>
                      <span>{x.value >0 ? x.label :""}</span>
                      <div  className="progress-bar space-element" style={{ height: '4px', backgroundColor: "#FFFFFF !important" }}>
                      </div>
                      <div className='progress-bar content-element' style={{ height: 'calc(100% - 4px)',backgroundColor: x.color }}>
                      </div>
                  </div>
                  )
              )
            }) : null}
          </div>
          {/* <div className="progress-content">
            <span className="content2">Estimated spends on school/year</span>
          </div> */}
        </div>
        <div className="content2" style={{ width: "100%" }}>
          <div className="progress">
            {percentList1 !== null ? percentList1.map((x, i) => {
              return (
                i == 0 ?
                  <div className={x.value === '100.00' ?
                    "progress-bar bg-white progress-bar-stripped curved":
                    "progress-bar bg-white progress-bar-stripped"}  
                    style={{ height: x.value + '%' }}>
                  </div>
                :
                ( i===1?
                  <div className="progress-bar main-element"
                  style={{ height: x.value + "%" ,backgroundColor: x.color}}>
                    <span>{x.value >0 ? x.label:""}</span>
                    <div className='progress-bar content-element' style={{  height: 'calc(100%)',backgroundColor: x.color }}> 
                    </div>
                </div>
                  :
                  <div className="progress-bar main-element"
                  style={{ height: x.value + "%",backgroundColor: x.color }}>
                    <span>{x.value >0 ? x.label:""}</span>
                    <div  className="progress-bar space-element" style={{ height: '4px', backgroundColor: "#FFFFFF !important" }}>
                    </div>
                    <div className='progress-bar content-element' style={{ height: 'calc(100% - 4px)',backgroundColor: x.color }}>
                    </div>
                </div>
                )
              )
            }) : null}
          </div>
          {/* <div className="progress-content">
            <span className="content2">Estimated spends on other expenses</span>
          </div> */}
        </div>
      </div>
    </div>
  )
}