import { navigate } from 'gatsby';
import React from 'react'

export default function Results() {
  React.useEffect(() => {
    console.log("result reloadeddddddddddddddddddddd11")
    if(localStorage.getItem("userId")){
      sessionStorage.setItem('activeStep',5);
      sessionStorage.setItem('userRedirectFlag',true);
      navigate('/tools/results')
    }
    else{
      sessionStorage.setItem('activeStep',4);
    }
  }, []);
  
  return (
    <div className="results-step-form row" id="results-step">
    </div>
  )
}